@media (min-width: 1440px) {
  .backgroundImg {
    background: url('./assets/bg-top.png') no-repeat top right fixed, #FFFFFF;
  }
}

@media (min-width: 1440px) {
  .loginBackgroundImg {
    background: url('./assets/bg-top.png') no-repeat top right fixed, #FFFFFF;
  }
}

@media (max-width: 1440px) and (min-width: 769px) {
  .loginBackgroundImg {
    background: url('./assets/bg-top.png') no-repeat top right fixed, #FFFFFF;
  }
}

@media (max-width: 1440px) and (min-width: 481px) {
  .tabletBackgroundImg {
    background: url('./assets/bg-top.png') no-repeat top right fixed, #FFFFFF;
  }
}

@media (max-width: 1440px) and (min-width: 769px) {
  .desktopBackgroundImg_Upgrade {
    background: url('./assets/bg-top.png') no-repeat top right fixed, url('./assets/bg-btm.png') no-repeat bottom left fixed , #FFFFFF;
  }
}

@media (min-width: 1440px) {
  .loginBackgroundImg_Upgrade {
    background: url('./assets/bg-top.png') no-repeat top right fixed, url('./assets/bg-btm.png') no-repeat bottom left fixed, #FFFFFF;
  }
}

@media (max-width: 1440px) and (min-width: 769px) {
  .loginBackgroundImg_Upgrade {
    background: url('./assets/bg-top.png') no-repeat top right fixed, url('./assets/bg-btm.png') no-repeat bottom left fixed, #FFFFFF;
  }
}

@media (max-width: 1440px) and (min-width: 481px) {
  .tabletBackgroundImg_Upgrade {
    background: url('./assets/bg-top.png') no-repeat top right fixed, url('./assets/bg-btm.png') no-repeat bottom left fixed, #FFFFFF;
  }
}

@media (max-width: 1440px) and (min-width: 769px) {
  .desktopBackgroundImg_Upgrade {
    background: url('./assets/bg-top.png') no-repeat top right fixed, url('./assets/bg-btm.png') no-repeat bottom left fixed , #FFFFFF;
  }
}

.footer{
  position: absolute;
  width: 100%;
  bottom: 20px;
}

.control_checkbox {
  font-size: 18px;
  position: relative;
  display: block;
  margin-bottom: 15px;
  padding-left: 30px;
  cursor: pointer;
}

.control_checkbox input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.control__indicator_purple {
  position: absolute;
  top: 2px;
  left: 0;
  width: 20px;
  height: 20px;
  background: #FFFFFF;
  border: 0.2px solid #8E68C8;
  border-radius: 4px
}

/* Hover and focus states */

.control_checkbox:hover input~.control__indicator_purple, .control_checkbox input:focus~.control__indicator_purple {
  background: white;
}

/* Checked state */

.control_checkbox input:checked~.control__indicator_purple {
  background: #FFFFFF;
}

/* Hover state whilst checked */

.control_checkbox:hover input:not([disabled]):checked~.control__indicator_purple, .control_checkbox input:checked:focus~.control__indicator_purple {
  background: #FFFFFF;
}

/* Disabled state */

.control_checkbox input:disabled~.control__indicator_purple {
  pointer-events: none;
  opacity: .6;
  background: #e6e6e6;
}

/* Check mark */

.control__indicator_purple:after {
  position: absolute;
  display: none;
  content: '';
}

/* Show check mark */

.control_checkbox input:checked~.control__indicator_purple:after {
  display: block;
}

/* Checkbox tick */

.control--checkbox_purple .control__indicator_purple:after {
  bottom: 4px;
  left: 6px;
  width: 5px;
  height: 10px;
  transform: rotate(45deg);
  border: solid #8E68C8;
  border-width: 0 2px 2px 0;
}

#mfCostTable tr:first-child td {
  background-color: #C0C0C0;
  font-weight: bold;
}